import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Input, Button, Select } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { formatPhoneNumber, internationalPhone, nationalFormatPhone, validatePhoneNumber } from "../../utils/FormatPhoneNumber/FormatPhoneNumber";
import { getPhoneAppointments } from "../../redux/actions/checking-in/checking-in";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import moment from "moment";
import { countryPhoneCode } from "../../utils/util/utility";

const { Option } = Select;
class CheckingPhoneContainer extends Component {
    state = {
        phone: "",
        error: "",
        loading: false,
    };

    inputRef = React.createRef();

    componentDidMount() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }

        if (this.props.phone) {
            this.setState({
                phone: nationalFormatPhone(
                    this.props.phone,
                    this.props.country
                ),
            });
        }
        if (this.props.isClientUrl) {
            const localClientData = JSON.parse(
                localStorage.getItem("checkingInUser")
            );
            if (localClientData && localClientData.phone) {
                this.setState({
                    phone: nationalFormatPhone(
                        localClientData.phone,
                        this.props.country
                    ),
                });
            }
        }
    }

    // componentDidUpdate(prevProps) {
    //     if(prevProps.phone !== this.props.phone){

    //         this.setState({
    //             phone: this.props.phone
    //         })
    //     }
    // }

    handleChange = (e) => {
        this.setState({
            phone: formatPhoneNumber(e.target.value, this.props.country),
        });
    };

    handleChangeCountry = (value) => {
        this.props.handleChangeCountry(value);
        this.setState({
            phone: formatPhoneNumber(this.state.phone, value),
        });
    };

    handleVerifyRecaptcha = async () => {
        const { executeRecaptcha } = this.props.googleReCaptchaProps;

        if (!executeRecaptcha) {
            return null;
        }

        return await executeRecaptcha();
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        // reCAPTCHA verification
        const token = await this.handleVerifyRecaptcha();

        const error = validatePhoneNumber(this.state.phone, this.props.country);
        const getPhoneAppointments = this.props.getPhoneAppointments;
        const today = moment().format("YYYY-MM-DD");
        const unformattedPhone = internationalPhone(
            this.state.phone,
            this.props.country
        );
        this.setState({ error }, () => {
            if (!error) {
                getPhoneAppointments(
                    this.props.location,
                    unformattedPhone,
                    token,
                    today
                );
            }
        });
    };

    render() {
        const { business, country, loyalty } = this.props;
        const loyaltyTitle = loyalty?.title || "";
        const loyaltyDescription = loyalty?.description || "";
        if (business) {
            return (
                <Row
                    justify='center'
                    style={{ padding: "20px", marginTop: "10%" }}
                >
                    <Col
                        md={16}
                        lg={14}
                        xl={12}
                        sm={24}
                        className=''
                        style={{ width: "100%" }}
                    >
                        <div className='box box-down cyan animate__animated animate__fadeInUp'>
                            <form onSubmit={this.handleSubmit}>
                                {business && <h2>{business.name}</h2>}
                                <p>Please enter your phone number: </p>
                                <Input
                                    ref={this.inputRef}
                                    onChange={this.handleChange}
                                    placeholder='Phone number'
                                    value={this.state.phone}
                                    // className="checking-in-input-number"
                                    size='large'
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                    }}
                                    type='text'
                                    inputMode='tel'
                                    allowClear
                                    addonBefore={
                                        <Select
                                            style={{ width: "110px" }}
                                            value={country}
                                            onChange={this.handleChangeCountry}
                                        >
                                            {countryPhoneCode.map((ctr) => (
                                                <Option
                                                    value={ctr.country}
                                                    key={ctr.country}
                                                >
                                                    {ctr.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    }
                                />
                                {this.state.error && (
                                    <p className='checking-in-phone-error'>
                                        {this.state.error}
                                    </p>
                                )}
                                <Row justify='center'>
                                    <small
                                        style={{
                                            color: "grey",
                                            // padding: "5px",
                                            marginTop: "20px",
                                        }}
                                    >
                                        This site is protected by reCAPTCHA and
                                        the Google{" "}
                                        <a
                                            target='_blank'
                                            rel='noreferrer'
                                            href='https://policies.google.com/privacy'
                                        >
                                            Privacy Policy
                                        </a>{" "}
                                        and{" "}
                                        <a
                                            target='_blank'
                                            rel='noreferrer'
                                            href='https://policies.google.com/terms'
                                        >
                                            Terms of Service
                                        </a>{" "}
                                        apply.
                                    </small>
                                </Row>
                                <Row
                                    justify='center'
                                    style={{ marginTop: "20px" }}
                                >
                                    <Button
                                        type='primary'
                                        loading={this.state.loading}
                                        size='large'
                                        shape='round'
                                        htmlType='submit'
                                        style={{ width: "100%", height: "50px" }}
                                    >
                                        Next <SendOutlined />
                                    </Button>
                                </Row>
                            </form>
                            <div
                                className=' animate__animated animate__fadeInUp'
                                style={{
                                    textAlign: "center",
                                    marginTop: "15px",
                                }}
                            >
                                <h1>{loyaltyTitle}</h1>
                                <p style={{ margin: "0px", padding: "0px" }}>{loyaltyDescription}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                // </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    business: state.checkingIn.business,
    appointments: state.checkingIn.appointments,
    phone: state.checkingIn.phone,
    loyalty: state.checkingIn.loyalty,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getPhoneAppointments: (business, phone, token, today) =>
            dispatch(getPhoneAppointments(business, phone, token, today)),
    };
};

export default withGoogleReCaptcha(
    connect(mapStateToProps, mapDispatchToProps)(CheckingPhoneContainer)
);
