import { Col, Rate, Row, Slider } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

const ClientLoyaltyPoints = () => {

    const clientLoyalty = useSelector(state => state.checkingIn.clientLoyalty)
    const loyalty = useSelector(state => state.checkingIn.loyalty)
    const getCap = () => {
        return loyalty.programType === "Entries"
            ? loyalty.cap
            : 10
    }
    const getStars = () => {
        if (loyalty.cap === 0) return "0"
        return loyalty.programType === "Entries"
            ? Number(clientLoyalty.currentPoint).toFixed(0)
            : Number(clientLoyalty.currentPoint / loyalty.cap * 10).toFixed(2)
    }

    const getMarks = (topUpDiscounts) => {
        let marks = {
            0: "0",
        }

        for (let topUpDiscount of topUpDiscounts) {
            marks[Number(topUpDiscount.atPoint)] =
                <div>
                    <div>{topUpDiscount.atPoint}</div>
                    <div>{topUpDiscount.discount}{topUpDiscount.discountType === "Percentage" ? "%" : "$"}</div>
                </div>
        }
        return marks
    }

    return (
        <Row
            justify="center"
            style={{
                padding: "0px 20px",
                width: "100%",
                position: "relative",
            }}
        >
            <Col md={16} lg={14} xl={12} sm={24} style={{ width: "100%" }}>
                <div style={{ marginTop: 0, textAlign: "center" }} className="box box-down green animate__animated animate__fadeInUp">
                    <h2 style={{ fontWeight: "bold" }}>
                        Your Loyalty Point {loyalty?.isCapHidden && <span style={{ color: "#f97316" }}>{Number(clientLoyalty.currentPoint).toFixed(2)}</span>}
                    </h2>
                    {!loyalty?.isCapHidden && <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {
                            !clientLoyalty.enableTopUp
                                ? <span style={{ color: "#f97316" }} > {Number(clientLoyalty.currentPoint).toFixed(2)} / {Number(loyalty?.cap).toFixed(2)}</span>
                                : <span style={{ color: "#f97316" }}> {Number(clientLoyalty.currentPoint).toFixed(2)} / {Number(loyalty?.topUpDiscounts[loyalty.topUpDiscounts.length - 1].atPoint).toFixed(2)}</span>
                        }
                    </div>}
                    {!loyalty?.isCapHidden && <div>
                        {!clientLoyalty?.enableTopUp
                            ? loyalty?.cap <= 10 ? <Rate disabled defaultValue={getStars()} count={getCap()} style={{ color: "#f97316" }} /> : ""
                            : <Slider
                                marks={getMarks(loyalty?.topUpDiscounts)}
                                value={Number(clientLoyalty?.currentPoint)}
                                max={loyalty.topUpDiscounts[clientLoyalty.topUpDiscounts.length - 1].atPoint}
                                style={{ paddingBottom: "30px" }}
                            />
                        }
                    </div>}
                </div>
            </Col>
        </Row >
    )
}

export default ClientLoyaltyPoints